import React, { Component, Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";

import {
    FaReact,
    FaSass
} from "react-icons/fa";
import {
    FiSmartphone,
    FiCode,
    FiDownload,
    FiCommand,
    FiHeadphones,
    FiBold,
    FiChevronUp
} from "react-icons/fi";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom';
import { FiX, FiMenu } from "react-icons/fi";
import { FaHandshake, FaRegWindowRestore } from "react-icons/fa";
import { HiOutlineLightBulb } from "react-icons/hi";
import { MdCamera } from "react-icons/md";
import { FaCamera } from "react-icons/fa";
import { ImVideoCamera } from "react-icons/im";
import { HiDesktopComputer } from "react-icons/hi";
import { AiOutlineComment } from "react-icons/ai";




const ServiceList = [
    {
        icon: <MdCamera />,
        title: 'Professional',
        description: 'ทีมงานเราใส่ใจทุกรายละเอียดตั้งแต่กระบวนการ Pre Production ไปจนถึง Post Production'
    },
    {
        icon: <HiOutlineLightBulb />,
        title: 'Creative IDEA',
        description: 'เราสามารถเสนอไอเดียรูปแบบใหม่ได้ตามที่ลูกค้าต้องการ'
    },
    {
        icon: <FaHandshake />,
        title: 'After-sales service',
        description: 'เราใส่ใจลูกค้าทุกท่านด้วยบริการหลังการขายสามารถปรึกษาได้ตลอด'
    },
]


const featureList = [
    {
        icon: <ImVideoCamera />,
        title: 'Video Production',
        subtitle: 'รับผลิตสื่อ ภาพนิ่ง วิดีโอทุกรูปแบบ ไม่ว่าจะเป็น Presentation, สัมภาษณ์, รีวิว, MV, ท่องเที่ยว, งานสัมนา, งานกิจกรรม, Vlog'
    },
    {
        icon: <FaCamera />,
        title: 'Photography',
        subtitle: 'บริการ ถ่ายภาพนิ่ง Portrait, รับปริญญา, ถ่ายภาพในสตูดิโอ, ถ่ายงานอีเว้นท์, ถ่ายอาหาร, ถ่ายภาพโรงแรม, ร้านอาหาร, คอนโด'
    },
    {
        icon: <HiDesktopComputer />,
        title: 'Graphic',
        subtitle: 'บริการ ออกแบบภาพโฆษณา ADs / Banner / Social Media ทั้ง Online และ Offline'
    },
    {
        icon: <FaRegWindowRestore />,
        title: 'Editor',
        subtitle: 'บริการตัดต่อวิดีโอ ให้ดูน่าสนใจ สัมภาษณ์ / Tiktok / IG Reels / Vlog / หนังสั้น / ซีรี่ส์ / สารคดี / โฆษณา'
    },
    {
        icon: <FiCode />,
        title: 'Website',
        subtitle: 'บริการออกแบบและพัฒนาเว็บไซต์ทุกประเภท ดีไซน์สวยงาม ทันสมัย รองรับ SEO'
    },
    {
        icon: <AiOutlineComment />,
        title: 'Marketing Online',
        subtitle: 'บริการดูแลระบบหลังบ้านครบวงจร (ปรับปรุงข้อมูล/สร้างยอดขาย/ดูแลระบบ/Ads Optimize/Content)'
    }
]


const portfolioPhoto = [
    { imageUrl: 'bmw-1.webp', },
    { imageUrl: 'bmw (2).webp', },
    { imageUrl: 'bmw (3).webp', },
    { imageUrl: 'bmw (4).webp', },
    { imageUrl: 'bmw (5).webp', },
    { imageUrl: 'bmw (6).webp', },
    { imageUrl: 'bmw (7).webp', },
    { imageUrl: 'bmw (8).webp', },
    { imageUrl: 'bmw (9).webp', },
    { imageUrl: 'bmw (10).webp', },
    { imageUrl: 'bmw (11).webp', },
    { imageUrl: 'bmw (12).webp', },

    { imageUrl: 'fti (1).webp', },
    { imageUrl: 'fti (2).webp', },
    { imageUrl: 'fti (3).webp', },
    { imageUrl: 'fti (4).webp', },
    { imageUrl: 'fti (5).webp', },
    { imageUrl: 'fti (6).webp', },

    { imageUrl: 'missyst (1).webp', },
    { imageUrl: 'missyst (2).webp', },
    { imageUrl: 'missyst (3).webp', },
    { imageUrl: 'missyst (4).webp', },
    { imageUrl: 'missyst (5).webp', },
    { imageUrl: 'missyst (6).webp', },

    { imageUrl: 'godung (1).webp', },
    { imageUrl: 'godung (2).webp', },
    { imageUrl: 'godung (3).webp', },
    { imageUrl: 'godung (4).webp', },
    { imageUrl: 'godung (5).webp', },
    { imageUrl: 'godung (6).webp', },

    { imageUrl: 'next (1).webp', },
    { imageUrl: 'next (2).webp', },
    { imageUrl: 'next (3).webp', },
    { imageUrl: 'next (4).webp', },
    { imageUrl: 'next (5).webp', },
    { imageUrl: 'next (6).webp', },
    { imageUrl: 'next (7).webp', },
    { imageUrl: 'next (8).webp', },
    { imageUrl: 'next (9).webp', },
    { imageUrl: 'next (10).webp', },
    { imageUrl: 'next (11).webp', },
    { imageUrl: 'next (12).webp', },
    { imageUrl: 'next (13).webp', },
    { imageUrl: 'next (14).webp', },
    { imageUrl: 'next (15).webp', },
    { imageUrl: 'next (16).webp', },
    { imageUrl: 'next (17).webp', },
    { imageUrl: 'next (18).webp', },

    { imageUrl: 'w1 (1).webp', },
    { imageUrl: 'w1 (2).webp', },
    { imageUrl: 'w1 (3).webp', },
    { imageUrl: 'w1 (4).webp', },
    { imageUrl: 'w1 (5).webp', },
    { imageUrl: 'w1 (6).webp', },

    { imageUrl: 'w2 (1).webp', },
    { imageUrl: 'w2 (2).webp', },
    { imageUrl: 'w2 (3).webp', },
    { imageUrl: 'w2 (4).webp', },
    { imageUrl: 'w2 (5).webp', },
    { imageUrl: 'w2 (6).webp', },

    { imageUrl: 'w3 (1).webp', },
    { imageUrl: 'w3 (2).webp', },
    { imageUrl: 'w3 (3).webp', },
    { imageUrl: 'w3 (4).webp', },
    { imageUrl: 'w3 (5).webp', },
    { imageUrl: 'w3 (6).webp', },

    { imageUrl: 'meraki (1).webp', },
    { imageUrl: 'meraki (2).webp', },
    { imageUrl: 'meraki (3).webp', },
    { imageUrl: 'meraki (4).webp', },
    { imageUrl: 'meraki (5).webp', },
    { imageUrl: 'meraki (6).webp', },

    { imageUrl: 'noble (1).webp', },
    { imageUrl: 'noble (2).webp', },
    { imageUrl: 'noble (3).webp', },
    { imageUrl: 'noble (4).webp', },
    { imageUrl: 'noble (5).webp', },
    { imageUrl: 'noble (6).webp', },

    { imageUrl: 'organic (1).webp', },
    { imageUrl: 'organic (2).webp', },
    { imageUrl: 'organic (3).webp', },
    { imageUrl: 'organic (4).webp', },
    { imageUrl: 'organic (5).webp', },
    { imageUrl: 'organic (6).webp', },

    { imageUrl: 'ramida (1).webp', },
    { imageUrl: 'ramida (2).webp', },
    { imageUrl: 'ramida (3).webp', },
    { imageUrl: 'ramida (4).webp', },
    { imageUrl: 'ramida (5).webp', },
    { imageUrl: 'ramida (6).webp', },

    { imageUrl: 'runysp (1).webp', },
    { imageUrl: 'runysp (2).webp', },
    { imageUrl: 'runysp (3).webp', },
    { imageUrl: 'runysp (4).webp', },
    { imageUrl: 'runysp (5).webp', },
    { imageUrl: 'runysp (6).webp', },

    { imageUrl: 'ingfa (1).webp', },
    { imageUrl: 'ingfa (2).webp', },
    { imageUrl: 'ingfa (3).webp', },
    { imageUrl: 'ingfa (4).webp', },
    { imageUrl: 'ingfa (5).webp', },
    { imageUrl: 'ingfa (6).webp', },

    { imageUrl: 'd1 (1).webp', },
    { imageUrl: 'd1 (2).webp', },
    { imageUrl: 'd1 (3).webp', },
    { imageUrl: 'd1 (4).webp', },
    { imageUrl: 'd1 (5).webp', },
    { imageUrl: 'd1 (6).webp', },

    { imageUrl: 'k (1).webp', },
    { imageUrl: 'k (2).webp', },
    { imageUrl: 'k (3).webp', },
    { imageUrl: 'k (4).webp', },
    { imageUrl: 'k (5).webp', },
    { imageUrl: 'k (6).webp', },

    { imageUrl: 'm ubon (1).webp', },
    { imageUrl: 'm ubon (2).webp', },
    { imageUrl: 'm ubon (3).webp', },
    { imageUrl: 'm ubon (4).webp', },
    { imageUrl: 'm ubon (5).webp', },
    { imageUrl: 'm ubon (6).webp', },
    { imageUrl: 'm ubon (7).webp', },
    { imageUrl: 'm ubon (8).webp', },
    { imageUrl: 'm ubon (9).webp', },
    { imageUrl: 'm ubon (10).webp', },
    { imageUrl: 'm ubon (11).webp', },
    { imageUrl: 'm ubon (12).webp', },

    { imageUrl: 'dd (1).webp', },
    { imageUrl: 'dd (2).webp', },
    { imageUrl: 'dd (3).webp', },
    { imageUrl: 'dd (4).webp', },
    { imageUrl: 'dd (5).webp', },
    { imageUrl: 'dd (6).webp', },
    { imageUrl: 'dd (7).webp', },
    { imageUrl: 'dd (8).webp', },
    { imageUrl: 'dd (9).webp', },
    { imageUrl: 'dd (10).webp', },
    { imageUrl: 'dd (11).webp', },
    { imageUrl: 'dd (12).webp', },

    { imageUrl: 'ddd (1).webp', },
    { imageUrl: 'ddd (2).webp', },
    { imageUrl: 'ddd (3).webp', },
    { imageUrl: 'ddd (4).webp', },
    { imageUrl: 'ddd (5).webp', },
    { imageUrl: 'ddd (6).webp', },

    { imageUrl: 'ub (1).webp', },
    { imageUrl: 'ub (2).webp', },
    { imageUrl: 'ub (3).webp', },
    { imageUrl: 'ub (4).webp', },
    { imageUrl: 'ub (5).webp', },
    { imageUrl: 'ub (6).webp', },
    { imageUrl: 'ub (7).webp', },
    { imageUrl: 'ub (8).webp', },
    { imageUrl: 'ub (9).webp', },
    { imageUrl: 'ub (10).webp', },
    { imageUrl: 'ub (11).webp', },
    { imageUrl: 'ub (12).webp', },

    { imageUrl: 'bike (1).webp', },
    { imageUrl: 'bike (2).webp', },
    { imageUrl: 'bike (3).webp', },
    { imageUrl: 'bike (4).webp', },
    { imageUrl: 'bike (5).webp', },
    { imageUrl: 'bike (6).webp', },
    { imageUrl: 'bike (7).webp', },
    { imageUrl: 'bike (8).webp', },
    { imageUrl: 'bike (9).webp', },
    { imageUrl: 'bike (10).webp', },
    { imageUrl: 'bike (11).webp', },
    { imageUrl: 'bike (12).webp', },
    { imageUrl: 'bike (13).webp', },
    { imageUrl: 'bike (14).webp', },
    { imageUrl: 'bike (15).webp', },
    { imageUrl: 'bike (16).webp', },
    { imageUrl: 'bike (17).webp', },
    { imageUrl: 'bike (18).webp', },
    { imageUrl: 'bike (19).webp', },
    { imageUrl: 'bike (20).webp', },
    { imageUrl: 'bike (21).webp', },
    { imageUrl: 'bike (22).webp', },
    { imageUrl: 'bike (23).webp', },
    { imageUrl: 'bike (24).webp', },
    { imageUrl: 'bike (25).webp', },
    { imageUrl: 'bike (26).webp', },
    { imageUrl: 'bike (27).webp', },
    { imageUrl: 'bike (28).webp', },
    { imageUrl: 'bike (29).webp', },
    { imageUrl: 'bike (30).webp', },

    { imageUrl: 'n1 (1).webp', },
    { imageUrl: 'n1 (2).webp', },
    { imageUrl: 'n1 (3).webp', },
    { imageUrl: 'n1 (4).webp', },
    { imageUrl: 'n1 (5).webp', },
    { imageUrl: 'n1 (6).webp', },
]

const portfolioGraphic = [
    { imageUrl: 'graphic (1).webp', },
    { imageUrl: 'graphic (2).webp', },
    { imageUrl: 'graphic (3).webp', },
    { imageUrl: 'graphic (4).webp', },
    { imageUrl: 'graphic (5).webp', },
    { imageUrl: 'graphic (6).webp', },
    { imageUrl: 'graphic (7).webp', },
    { imageUrl: 'graphic (8).webp', },
    { imageUrl: 'graphic (9).webp', },
    { imageUrl: 'graphic (10).webp', },
    { imageUrl: 'graphic (11).webp', },
    { imageUrl: 'graphic (12).webp', },
    { imageUrl: 'graphic (13).webp', },
    { imageUrl: 'graphic (14).webp', },
    { imageUrl: 'graphic (15).webp', },
    { imageUrl: 'graphic (16).webp', },
    { imageUrl: 'graphic (17).webp', },
    { imageUrl: 'graphic (18).webp', },
    { imageUrl: 'graphic (19).webp', },
    { imageUrl: 'graphic (20).webp', },
    { imageUrl: 'graphic (21).webp', },
    { imageUrl: 'graphic (22).webp', },
    { imageUrl: 'graphic (23).webp', },
    { imageUrl: 'graphic (24).webp', },
    { imageUrl: 'graphic (25).webp', },
    { imageUrl: 'graphic (26).webp', },
    { imageUrl: 'graphic (27).webp', },
    { imageUrl: 'graphic (28).webp', },
    { imageUrl: 'graphic (29).webp', },
    { imageUrl: 'graphic (30).webp', },
    { imageUrl: 'graphic (31).webp', },
    { imageUrl: 'graphic (32).webp', },
    { imageUrl: 'graphic (33).webp', },
    { imageUrl: 'graphic (34).webp', },
    { imageUrl: 'graphic (35).webp', },
    { imageUrl: 'graphic (36).webp', },
    { imageUrl: 'graphic (37).webp', },
    { imageUrl: 'graphic (38).webp', },
    { imageUrl: 'graphic (39).webp', },
    { imageUrl: 'graphic (40).webp', },
    { imageUrl: 'graphic (41).webp', },
    { imageUrl: 'graphic (42).webp', },
    { imageUrl: 'graphic (43).webp', },
    { imageUrl: 'graphic (44).webp', },
    { imageUrl: 'graphic (45).webp', },
    { imageUrl: 'graphic (46).webp', },
    { imageUrl: 'graphic (47).webp', },
    { imageUrl: 'graphic (48).webp', },
    { imageUrl: 'graphic (49).webp', },
    { imageUrl: 'graphic (50).webp', },
    { imageUrl: 'graphic (51).webp', },
    { imageUrl: 'graphic (52).webp', },
    { imageUrl: 'graphic (53).webp', },
    { imageUrl: 'graphic (54).webp', },
    { imageUrl: 'graphic (55).webp', },
    { imageUrl: 'graphic (56).webp', },
    { imageUrl: 'graphic (57).webp', },
    { imageUrl: 'graphic (58).webp', },
]

const portfolioWebsite = [
    {
        url: 'https://saraburiitblock.com',
        imageUrl: 'website (1).jpg',
        title: 'สระบุรีอิฐบล๊อค',
    },
    {
        url: 'https://smartthink.co.th',
        imageUrl: 'website (2).jpg',
        title: 'Smart Think',
    },
]

const ourClients = [
    { imageUrl: 'client (1).png', },
    { imageUrl: 'client (2).png', },
    { imageUrl: 'client (3).png', },
    { imageUrl: 'client (4).png', },
    { imageUrl: 'client (5).png', },
    { imageUrl: 'client (6).png', },
    { imageUrl: 'client (7).png', },
    { imageUrl: 'client (8).png', },
    { imageUrl: 'client (9).png', },
    { imageUrl: 'client (10).png', },
    { imageUrl: 'client (11).png', },
    { imageUrl: 'client (12).png', },
    { imageUrl: 'client (13).png', },
    { imageUrl: 'client (14).png', },
    { imageUrl: 'client (15).png', },
    { imageUrl: 'client (16).png', },
    { imageUrl: 'client (17).png', },
    { imageUrl: 'client (18).png', },
    { imageUrl: 'client (19).png', },
    { imageUrl: 'client (20).png', },
    { imageUrl: 'client (21).png', },
    { imageUrl: 'client (22).png', },
    { imageUrl: 'client (23).png', },
    { imageUrl: 'client (24).png', },
    { imageUrl: 'client (25).png', },
    { imageUrl: 'client (26).png', },
    { imageUrl: 'client (27).png', },
    { imageUrl: 'client (28).png', },
    { imageUrl: 'client (29).png', },
    { imageUrl: 'client (30).png', },
    { imageUrl: 'client (31).png', },
    { imageUrl: 'client (32).png', },
]

class Demo extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function () {
            console.log('All assets are loaded')
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    render() {
        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        return (
            <div className="active-dark">
                {/* Start Banner Area  */}
                <Header logoname="logo.png" />


                {/* Start Slider Area   */}
                <div className="pv-slider-area slider-wrapper">


                    {/* *************************************************************** */}


                    <video id="bgVideo" controls preload="true" autoPlay loop muted>
                        <source src="/assets/video/bgvideo.mp4" type="video/webm" />

                    </video>


                    {/* *************************************************************** */}


                    <div className="slider-activation">
                        {/* Start Single Slide */}
                        <div className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image bg_image--34" data-black-overlay="9">
                            <div className="container position-relative">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="inner">
                                            <h1 className="title theme-gradient">BANGFAI OFFICIAL</h1>
                                        </div>
                                        <div className="inner theme-white">
                                            <h3>รับผลิตสื่อ ภาพนิ่ง วิดีโอทุกรูปแบบ ไม่ว่าจะเป็น Presentation, สัมภาษณ์, รีวิว, MV, ท่องเที่ยว, งานสัมนา, งานกิจกรรม, Vlog</h3>
                                        </div>
                                    </div>
                                </div>

                                {/* Start Service Area */}
                                <div className="service-wrapper service-white">
                                    <div className="row row--25">
                                        {ServiceList.map((val, i) => (
                                            <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                                <div className="service service__style--1">
                                                    <div className="icon">
                                                        {val.icon}
                                                    </div>
                                                    <div className="content">
                                                        <h4 className="title">{val.title}</h4>
                                                        <p>{val.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {/* End Service Area */}

                            </div>
                        </div>
                        {/* End Single Slide */}
                    </div>
                </div>
                {/* End Slider Area   */}


                {/* End Banner Area  */}

                {/* Start Preview Main Wrapper */}
                <div className="section-title text-center  ptb--30 ">
                    <h2 className="title">บริการของเรา</h2>
                </div>
                <div className="main-wrapper">

                    {/* Start Feature Area  */}
                    <div id="feature" className="service-area  ptb--0">
                        <div className="wrapper plr--120">
                            <div className="row service-main-wrapper">
                                {/* Start Single Feature  */}
                                {featureList.map((value, i) => (
                                    <div className="col-lg-6 col-xl-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <div className="service service__style--2 text-center">
                                            <div className="icon">
                                                {value.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title text_color--black">{value.title}</h3>
                                                <p className="subtitle">{value.subtitle}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {/* End Single Feature  */}
                            </div>
                        </div>
                    </div>
                    {/* End Feature Area  */}

                    {/* Start Main Demo Area  */}
                    <div id="demo" className="home-demo-area bg_color--1 ptb--120">
                        <div className="wrapper plr--120">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center pb--30">
                                        <h2 className="title">ผลงานที่ภูมิใจนำเสนอ</h2>
                                    </div>
                                </div>
                            </div>
                            <Tabs>
                                <div className="row text-center">
                                    <div className="col-lg-12">
                                        <div className="tablist-inner">
                                            <TabList className="pv-tab-button text-center mt--30">
                                                <Tab><span>วิดีโอ</span></Tab>
                                                <Tab><span>ภาพนิ่ง</span></Tab>
                                                <Tab><span>กราฟิก</span></Tab>
                                                <Tab><span>เว็บไซต์</span></Tab>
                                            </TabList>
                                        </div>
                                    </div>
                                </div>

                                <TabPanel className="">
                                    <div className="portfolio-area">
                                        <Tabs>
                                            <div className="row text-center">
                                                <div className="col-lg-12">
                                                    <div className="tablist-inner">
                                                        <TabList className="pv-tab-button-2 text-center mt--30">
                                                            <Tab><span>โฆษณา</span></Tab>
                                                            <Tab><span>อีเวนท์</span></Tab>
                                                            <Tab><span>สัมภาษณ์</span></Tab>
                                                            <Tab><span>ร้านอาหาร คาเฟ่</span></Tab>
                                                            <Tab><span>คลิปสั้น</span></Tab>
                                                            <Tab><span>Presentation</span></Tab>
                                                            <Tab><span>อื่นๆ</span></Tab>
                                                        </TabList>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* โฆษณา */}
                                            <TabPanel className="row">
                                                <div className="portfolio-area">
                                                    <div className="col-12">
                                                        <div class="row">
                                                            <div class="grid-container">

                                                                <div class="grid-item">
                                                                    <div class="VideoPortfolio"><iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="bangfaiofficial" width="100%" height="100%" src="https://www.youtube.com/embed/e4dT9Q9zryg?si=SZtnp1ovYNwfar-6?autoplay=1&amp;mute=1&amp;controls=1&amp;loop=0&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"></iframe></div>

                                                                </div>
                                                                <div class="grid-item">
                                                                    <h4>เซียนหรั่ง x บิวบอง ตราสัญลักษณ์บั้งไฟ 5 สี ยโสธร</h4>
                                                                    <h5>สำนักงานพาณิชย์จังหวัดยโสธร</h5>
                                                                    <p>จัดทำโฆษณาตราสัญลักษณ์บั้งไฟ 5 สี รับรองคุณภาพ และมาตรฐานผลิตภัณฑ์และบริการของจังหวัดยโสธร</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="grid-container">

                                                                <div class="grid-item">
                                                                    <div class="VideoPortfolio"><iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="bangfaiofficial" width="100%" height="100%" src="https://www.youtube.com/embed/tagLFy9KTEY?si=Q50DTpLbKAf6Udlw?autoplay=1&amp;mute=1&amp;controls=1&amp;loop=0&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"></iframe></div>

                                                                </div>
                                                                <div class="grid-item">
                                                                    <h4>เกษตรนวัตกรรม เพื่อความสุขที่ยั่งยืน</h4>
                                                                    <h5>ข้าวอินทรีย์ดงแคนใหญ่</h5>
                                                                    <p>ถ่ายและตัดต่อวีดีโอส่งประกวดเกษตรกรสำนึกรักบ้านเกิด ประจำปี 2565</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="grid-container">

                                                                <div class="grid-item">
                                                                    <div class="VideoPortfolio"><iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="bangfaiofficial" width="100%" height="100%" src="https://www.youtube.com/embed/NNgzNKf_6hQ?autoplay=1&amp;mute=1&amp;controls=1&amp;loop=0&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"></iframe></div>

                                                                </div>
                                                                <div class="grid-item">
                                                                    <h4>มินิซีรีส์ EP.1/10 "หัวข้อสินค้าอินทรีย์ดีต่อสุขภาพ"</h4>
                                                                    <h5>สำนักงานพาณิชย์จังหวัดยโสธร</h5>
                                                                    <p>จัดทำโฆษณาโปรโมทผลิตภัณฑ์เกษตรอินทรีย์จังหวัดยโสธร</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </TabPanel>

                                            {/* อีเวนท์ */}
                                            <TabPanel className="row">
                                                <div className="portfolio-area">
                                                    <div className="col-12">
                                                        <div class="row">
                                                            <div class="grid-container">
                                                                <div class="grid-item">
                                                                    <div class="VideoPortfolio"><iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="bangfaiofficial" width="100%" height="100%" src="https://www.youtube.com/embed/ve78wucjd5M?si=NoFOEHU4Q5MzsBWI?autoplay=1&amp;mute=1&amp;controls=1&amp;loop=0&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"></iframe></div>
                                                                </div>
                                                                <div class="grid-item">
                                                                    <h4>BMW GOLF CUP 2567</h4>
                                                                    <h5>BMW Millennium Auto-Ubon</h5>
                                                                    <p>ถ่ายและตัดต่อวีดีโอบรรยากาศประเดิมการแข่งขันแรกของ BMW GOLF CUP รอบคัดเลือกปี 2567 สนามแรกที่สนามกอล์ฟเขื่อนสิรินธร จังหวัดอุบลราชธานี</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="grid-container">
                                                                <div class="grid-item">
                                                                    <div class="VideoPortfolio"><iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="bangfaiofficial" width="100%" height="100%" src="https://www.youtube.com/embed/jOzlSJ8gWfg?si=qR79HykOAdebO6Hi?autoplay=1&amp;mute=1&amp;controls=1&amp;loop=0&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"></iframe></div>
                                                                </div>
                                                                <div class="grid-item">
                                                                    <h4>THE NEXT REVOLUTION OF MOBILITY LIFESTYLE IN THE NORTHEAST.</h4>
                                                                    <h5>Millennium Auto Ubon</h5>
                                                                    <p>โชว์รูมและศูนย์บริการครบวงจร ‘Retail Next’ ของ มิลเลนเนียม ออโต้ บีเอ็มดับเบิลยู, มินิ และ บีเอ็มดับเบิลยูมอเตอร์ราด ณ MGC-ASIA AUTOPLEX UBON RATCHATHANI</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="grid-container">
                                                                <div class="grid-item">
                                                                    <div class="VideoPortfolio"><iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="bangfaiofficial" width="100%" height="100%" src="https://www.youtube.com/embed/bZKkqJcpuzs?si=iXUFDyIiH3ucfGur?autoplay=1&amp;mute=1&amp;controls=1&amp;loop=0&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"></iframe></div>
                                                                </div>
                                                                <div class="grid-item">
                                                                    <h4>𝐔𝐛𝐨𝐧 𝐑𝐚𝐭𝐜𝐡𝐚𝐭𝐡𝐚𝐧𝐢 𝐀𝐮𝐭𝐨 𝐅𝐞𝐬𝐭𝐢𝐯𝐚𝐥 𝐖𝐞𝐞k</h4>
                                                                    <h5>Millennium Auto Ubon</h5>
                                                                    <p>งานมหกรรมยานยนต์ของทางMillennium Auto Ubonratchathani ที่รวบรวมรถแบรนด์ดังมาไว้ในงานเดียว ไม่ว่าจะเป็น BMW, MINI, BMW Motorrad และ Harley-Davidson</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </TabPanel>


                                            {/* สัมภาษณ์ */}
                                            <TabPanel className="row">
                                                <div className="portfolio-area">
                                                    <div className="col-12">
                                                        <div class="row">
                                                            <div class="grid-container">
                                                                <div class="grid-item">
                                                                    <div class="VideoPortfolio"><iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="bangfaiofficial" width="100%" height="100%" src="https://www.youtube.com/embed/CE6H63kql3U?si=sYg7SYnIRRrXFZI6?autoplay=1&amp;mute=1&amp;controls=1&amp;loop=0&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"></iframe></div>
                                                                </div>
                                                                <div class="grid-item">
                                                                    <h4>ถ่ายวิดีโอโปรโมทคลินิก</h4>
                                                                    <h5>PEETZ Clinic พีทซ์ คลินิก</h5>
                                                                    <p>จังหวัดยโสธร</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="grid-container">
                                                                <div class="grid-item">
                                                                    <div class="VideoPortfolio"><iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="bangfaiofficial" width="100%" height="100%" src="https://www.youtube.com/embed/RQ-7zMKhpxM?si=3vNlvKr4kG5M0BYL?autoplay=1&amp;mute=1&amp;controls=1&amp;loop=0&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"></iframe></div>
                                                                </div>
                                                                <div class="grid-item">
                                                                    <h4>เกษตรอินทรีย์ วิถียโสธร</h4>
                                                                    <h5>โกดังข้าว ไชยกุลฟาร์มเอาท์เลท</h5>
                                                                    <p>จัดทำคลิปประชาสัมพันธ์รายการ เกษตรอินทรีย์ วิถียโสธร</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="grid-container">
                                                                <div class="grid-item">
                                                                    <div class="VideoPortfolio"><iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="bangfaiofficial" width="100%" height="100%" src="https://www.youtube.com/embed/x5PK2J7qSeo?si=p7MofpvAy_ZBWES5?autoplay=1&amp;mute=1&amp;controls=1&amp;loop=0&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"></iframe></div>
                                                                </div>
                                                                <div class="grid-item">
                                                                    <h4>เกษตรอินทรีย์ วิถียโสธร</h4>
                                                                    <h5>เกษตรอินทรีย์ ดงแคนใหญ่</h5>
                                                                    <p>จัดทำคลิปประชาสัมพันธ์รายการ เกษตรอินทรีย์ วิถียโสธร</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="grid-container">
                                                                <div class="grid-item">
                                                                    <div class="VideoPortfolio"><iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="bangfaiofficial" width="100%" height="100%" src="https://www.youtube.com/embed/x5?autoplay=1&amp;mute=1&amp;controls=1&amp;loop=0&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"></iframe></div>
                                                                </div>
                                                                <div class="grid-item">
                                                                    <h4>ปั้มน้ำมัน เอ็น ซี ปิโตรเลียม</h4>
                                                                    <h5>ถ่ายสัมภาษณ์มุมมองผู้บริหาร</h5>
                                                                    <p>ถ่ายสัมภาษณ์มุมมองผู้บริหาร ปั้มน้ำมัน เอ็น ซี ปิโตรเลียม เติมน้ำมันไปพร้อมกับการสร้างชุมชน ปั้มน้ำมันที่ให้ความสำคัญกับความรับผิดชอบต่อชุมชนและสิ่งแวดล้อม</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </TabPanel>


                                            {/* ร้านอาหาร คาเฟ่ */}
                                            <TabPanel className="row">
                                                <div className="portfolio-area">
                                                    <div className="col-12">
                                                        <div class="row">
                                                            <div class="grid-container">
                                                                <div class="grid-item">
                                                                    <div class="VideoPortfolio"><iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="bangfaiofficial" width="100%" height="100%" src="https://www.youtube.com/embed/oTOW0sLbqfg?si=PfG3poGJ0uv4AY2b?autoplay=1&amp;mute=1&amp;controls=1&amp;loop=0&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"></iframe></div>
                                                                </div>
                                                                <div class="grid-item">
                                                                    <h4>เจ๊ยุ ร้อยเอ็ด ทุเรียนคัด</h4>
                                                                    <h5>จังหวัดร้อยเอ็ด</h5>
                                                                    <p>จัดทำคลิปประชาสัมพันธ์ร้านทุเรียน จังหวัดร้อยเอ็ด</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="grid-container">
                                                                <div class="grid-item">
                                                                    <div class="VideoPortfolio"><iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="bangfaiofficial" width="100%" height="100%" src="https://www.youtube.com/embed/RQ?autoplay=1&amp;mute=1&amp;controls=1&amp;loop=0&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"></iframe></div>
                                                                </div>
                                                                <div class="grid-item">
                                                                    <h4>Vachi Yasothon</h4>
                                                                    <h5>ถ่ายโปรโมทคาเฟ่</h5>
                                                                    <p>ถ่ายสัมภาษณ์ร้าน Vachi Yasothon กาแฟสดฮิปๆสไตล์ Loft ในยโสธร Passion to Profession</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="grid-container">
                                                                <div class="grid-item">
                                                                    <div class="VideoPortfolio"><iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="bangfaiofficial" width="100%" height="100%" src="https://www.youtube.com/embed/x5?autoplay=1&amp;mute=1&amp;controls=1&amp;loop=0&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"></iframe></div>
                                                                </div>
                                                                <div class="grid-item">
                                                                    <h4>MissAree the Garden</h4>
                                                                    <h5>ถ่ายโปรโมทคาเฟ่</h5>
                                                                    <p>ถ่ายสัมภาษณ์ร้าน MissAree the Garden มิสอารีย์ เดอะ การ์เด้น ร้านอาหารและกาแฟที่เอาใจใส่กับการคัดสรรวัตถุดิบจากแหล่งที่มีคุณภาพ ด้วยการทำกาแฟในแบบ"Speedbar"</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </TabPanel>


                                            {/* คลิปสั้น */}
                                            <TabPanel className="row">
                                                <div className="portfolio-area">
                                                    <div className="col-12">
                                                        <div class="row">
                                                            <div class="grid-container">
                                                                <div class="grid-item">
                                                                    <div class="VideoPortfolio"><iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="bangfaiofficial" width="100%" height="100%" src="https://www.youtube.com/embed/wvkBptlM0KE?si=hvnCxfdVpXNGrqwf?autoplay=1&amp;mute=1&amp;controls=1&amp;loop=0&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"></iframe></div>
                                                                </div>
                                                                <div class="grid-item">
                                                                    <h4>ลาบยโส ของแซ่บอีสานจากจังหวัดยโสธร</h4>
                                                                    <h5>สำนักงานพาณิชย์จังหวัดยโสธร</h5>
                                                                    <p>จัดทำโฆษณาสั้น ลาบยโส ของแซ่บอีสานจากจังหวัดยโสธร</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="grid-container">
                                                                <div class="grid-item">
                                                                    <div class="VideoPortfolio"><iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="bangfaiofficial" width="100%" height="100%" src="https://www.youtube.com/embed/CcHSIv-8OaQ?si=snGkk3ShgQp1nNiC?autoplay=1&amp;mute=1&amp;controls=1&amp;loop=0&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"></iframe></div>
                                                                </div>
                                                                <div class="grid-item">
                                                                    <h4>ข้าวอินทรีย์จากครอบครัวชาวนา</h4>
                                                                    <h5>สำนักงานพาณิชย์จังหวัดยโสธร</h5>
                                                                    <p>จัดทำโฆษณาสั้น ข้าวอินทรีย์จากครอบครัวชาวนา อำเภอกุดชุม จังหวัดยโสธรเพาะปลูกโดยปราศจากการใช้สารเคมีสังเคราะห์</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="grid-container">
                                                                <div class="grid-item">
                                                                    <div class="VideoPortfolio"><iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="bangfaiofficial" width="100%" height="100%" src="https://www.youtube.com/embed/vkKo_SflPrY?si=mt5GNGcayDINiFws?autoplay=1&amp;mute=1&amp;controls=1&amp;loop=0&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"></iframe></div>
                                                                </div>
                                                                <div class="grid-item">
                                                                    <h4>ดอกกระเจียวหวาน</h4>
                                                                    <h5>สำนักงานพาณิชย์จังหวัดยโสธร</h5>
                                                                    <p>จัดทำโฆษณาสั้น ดอกกระเจียวหวาน ดอกกระเจียวอินทรีย์ บ้านโคกนาโก ยโสธร</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </TabPanel>

                                            {/* Presentation */}
                                            <TabPanel className="row">
                                                <div className="portfolio-area">
                                                    <div className="col-12">
                                                        <div class="row">
                                                            <div class="grid-container">
                                                                <div class="grid-item">
                                                                    <div class="VideoPortfolio"><iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="bangfaiofficial" width="100%" height="100%" src="https://www.youtube.com/embed/W6lvQpAJW1A?si=VI6RM7K-DrvOjX7M?autoplay=1&amp;mute=1&amp;controls=1&amp;loop=0&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"></iframe></div>
                                                                </div>
                                                                <div class="grid-item">
                                                                    <h4>Presentation กลุ่มงานอาชีวเวชกรรม</h4>
                                                                    <h5>โรงพยาบาลยโสธร</h5>
                                                                    <p>จัดทำคลิป Presentation</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="grid-container">
                                                                <div class="grid-item">
                                                                    <div class="VideoPortfolio"><iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="bangfaiofficial" width="100%" height="100%" src="https://www.youtube.com/embed/WWWkeWtRyDM?si=D1H23TMA0IUIKPck?autoplay=1&amp;mute=1&amp;controls=1&amp;loop=0&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"></iframe></div>
                                                                </div>
                                                                <div class="grid-item">
                                                                    <h4>ศจช.ดีเด่น จ.ยโสธร ปี 2566</h4>
                                                                    <h5>ศจช.ตำบลลุมพุก อำเภอคำเขื่อนแก้ว จังหวัดยโสธร</h5>
                                                                    <p>จัดทำคลิป Presentation</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="grid-container">
                                                                <div class="grid-item">
                                                                    <div class="VideoPortfolio"><iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="bangfaiofficial" width="100%" height="100%" src="https://www.youtube.com/embed/tWolqKLFw28?si=r-z9t9T1yH0MCisg?autoplay=1&amp;mute=1&amp;controls=1&amp;loop=0&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"></iframe></div>
                                                                </div>
                                                                <div class="grid-item">
                                                                    <h4>Presentation ปั่นแสบปอด</h4>
                                                                    <h5>อ.เลิงนกทา จ.ยโสธร</h5>
                                                                    <p>จัดทำคลิป Presentation</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="grid-container">
                                                                <div class="grid-item">
                                                                    <div class="VideoPortfolio"><iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="bangfaiofficial" width="100%" height="100%" src="https://www.youtube.com/embed/tW?autoplay=1&amp;mute=1&amp;controls=1&amp;loop=0&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"></iframe></div>
                                                                </div>
                                                                <div class="grid-item">
                                                                    <h4>จุดเริ่มต้นของตำนานบุญบั้งไฟ</h4>
                                                                    <h5>หอการค้าจังหวัดยโสธร</h5>
                                                                    <p>จัดทำคลิป Presentation</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </TabPanel>

                                            {/* อื่นๆ */}
                                            <TabPanel className="row">
                                                <div className="portfolio-area">
                                                    <div className="col-12">
                                                        <div class="row">
                                                            <div class="grid-container">
                                                                <div class="grid-item">
                                                                    <div class="VideoPortfolio"><iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="bangfaiofficial" width="100%" height="100%" src="https://www.youtube.com/embed/YvWWInteQZo?si=k0qejqRCfHpoXx3D?autoplay=1&amp;mute=1&amp;controls=1&amp;loop=0&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"></iframe></div>
                                                                </div>
                                                                <div class="grid-item">
                                                                    <h4>บุญผะเหวดวัดโพธิ์สีทองบ้านห้องข่า</h4>
                                                                    <h5>จังหวัดยโสธร</h5>
                                                                    <p>ถ่าย+ตัดต่อ</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="grid-container">
                                                                <div class="grid-item">
                                                                    <div class="VideoPortfolio"><iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="bangfaiofficial" width="100%" height="100%" src="https://www.youtube.com/embed/QPqzpdJCZT0?si=iymGfwun3Ov1L2ex?autoplay=1&amp;mute=1&amp;controls=1&amp;loop=0&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"></iframe></div>
                                                                </div>
                                                                <div class="grid-item">
                                                                    <h4>วิดีโอบรรยากาศงานมรณะ 1</h4>
                                                                    <h5>จังหวัดยโสธร</h5>
                                                                    <p>ถ่าย+ตัดต่อ</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="grid-container">
                                                                <div class="grid-item">
                                                                    <div class="VideoPortfolio"><iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="bangfaiofficial" width="100%" height="100%" src="https://www.youtube.com/embed/aDk2Iepyjes?si=NEt78c12Esb_sdUC?autoplay=1&amp;mute=1&amp;controls=1&amp;loop=0&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"></iframe></div>
                                                                </div>
                                                                <div class="grid-item">
                                                                    <h4>วิดีโอบรรยากาศงานมรณะ 2</h4>
                                                                    <h5>จังหวัดยโสธร</h5>
                                                                    <p>ถ่าย+ตัดต่อ</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="grid-container">
                                                                <div class="grid-item">
                                                                    <div class="VideoPortfolio"><iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="bangfaiofficial" width="100%" height="100%" src="https://www.youtube.com/embed/z7cBDKhaBLM?si=jPA3f3Fi2q5yj9QF?autoplay=1&amp;mute=1&amp;controls=1&amp;loop=0&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"></iframe></div>
                                                                </div>
                                                                <div class="grid-item">
                                                                    <h4>Yasothon Run2023 season 2</h4>
                                                                    <h5>หอการค้าจังหวัดยโสธร</h5>
                                                                    <p>ถ่าย+ตัดต่อ</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="grid-container">
                                                                <div class="grid-item">
                                                                    <div class="VideoPortfolio"><iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="bangfaiofficial" width="100%" height="100%" src="https://www.youtube.com/embed/OAYVMh016EI?si=00yqr1Hu0bfaK-sP?autoplay=1&amp;mute=1&amp;controls=1&amp;loop=0&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"></iframe></div>
                                                                </div>
                                                                <div class="grid-item">
                                                                    <h4>พาทัวร์ EP. 1 คุณพ่อปิยะทัศน์</h4>
                                                                    <h5>SMART THINK</h5>
                                                                    <p>ถ่ายสัมภาษณ์</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="grid-container">
                                                                <div class="grid-item">
                                                                    <div class="VideoPortfolio"><iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="bangfaiofficial" width="100%" height="100%" src="https://www.youtube.com/embed/z7j2iN_9KzE?si=b_uArbt1wPWueoWZ?autoplay=1&amp;mute=1&amp;controls=1&amp;loop=0&amp;playsinline=1&amp;enablejsapi=1&amp;widgetid=1"></iframe></div>
                                                                </div>
                                                                <div class="grid-item">
                                                                    <h4>พาทัวร์ EP. 2 บ้านกะสวน</h4>
                                                                    <h5>SMART THINK</h5>
                                                                    <p>ถ่ายสัมภาษณ์</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </TabPanel>

                                        </Tabs>
                                    </div>
                                </TabPanel>

                                {/* ภาพนิ่ง */}
                                <TabPanel className="portfolioPhoto row">
                                    {portfolioPhoto.map((value, index) => (
                                        // Start Single Demo 
                                        <div key={index} className="col-lg-2 col-md-3 col-sm-3 col-6">
                                            <div className="single-demo">
                                                <img src={`/assets/images/portfolio/album/${value.imageUrl}`} alt="bangfaiofficial" />
                                            </div>
                                        </div>
                                    ))}
                                </TabPanel>

                                {/* กราฟิก */}
                                <TabPanel className="row">
                                    {portfolioGraphic.map((value, index) => (
                                        // Start Single Demo 
                                        <div key={index} className="col-lg-2 col-md-3 col-sm-3 col-6">
                                            <div className="single-demo">
                                                <img src={`/assets/images/portfolio/graphic/${value.imageUrl}`} alt="bangfaiofficial" />
                                            </div>
                                        </div>
                                        // End Single Demo
                                    ))}
                                </TabPanel>

                                {/* เว็บไซต์ */}
                                <TabPanel className="row">
                                    {portfolioWebsite.map((value, index) => (
                                        // Start Single Demo 
                                        <div key={index} className="col-lg-3 col-md-6 col-sm-6 col-12">
                                            <div className="single-demo">
                                                <a target="_blank" rel="noopener noreferrer" href={`${value.url} `}>
                                                    <img src={`/assets/images/portfolio/website/${value.imageUrl}`} alt="bangfaiofficial" />
                                                    <h3 className="title">{value.title}</h3>
                                                </a>
                                            </div>
                                        </div>
                                        // End Single Demo
                                    ))}
                                </TabPanel>

                            </Tabs>
                        </div>
                    </div>
                    {/* End Main Demo Area  */}

                    {/* ลูกค้าที่เคยใช้บริการ  */}
                    <div className="our-clients-area ptb--0">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <h2 className="title mt--20 theme-gradient font-700">Our Clients ลูกค้าที่เคยใช้บริการ</h2>
                            </div>
                        </div>
                        <div className="wrapper plr--120">
                            <div className="row">
                                {ourClients.map((value, index) => (
                                    <div key={index} className="col-lg-2 col-md-3 col-sm-6 col-12">
                                        <div className="single-demo">
                                            <img src={`/assets/images/ourclients/${value.imageUrl}`} className="image" alt="bangfaiofficial" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* ลูกค้าที่เคยใช้บริการ  */}

                </div>
                {/* End Preview Main Wrapper */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </div>
        )
    }
}
export default Demo;
