import React, { Component } from "react";
import { Link } from 'react-router-dom';
import {FaInstagram, FaFacebookF, FaLine } from "react-icons/fa";
import { SiTiktok } from "react-icons/si";
const logoUrl = <img src="/assets/images/logo/logo-light.png" alt="BANGFAI OFFICIAL" />;

const SocialShare = [
    { Social: <FaFacebookF />, link: 'https://www.facebook.com/bangfaiofficial' },
    { Social: <FaInstagram />, link: 'https://www.instagram.com/bangfaiofficial/' },
    { Social: <FaLine />, link: 'https://lin.ee/GyauX4t1' },
    { Social: <SiTiktok />, link: 'https://www.tiktok.com/@bangfai.official' },
]

class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <footer className="footer-area footer-style-01 bg_color--6">
                    {/* Start Call to Action Area  */}
                    <div className="im-call-to-action-area ptb--70 im-separator">
                        <div className="container">
                            <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12">
                                <div className="inner">
                                    <h1 className="theme-gradient font-700 mb--0">ให้เราได้ดูแลงานสื่อของท่าน</h1>
                                </div>
                                <a className="btn-default btn-opacity mt--30" href="#button">ประเมินราคา</a>
                                <a className="btn-default btn-opacity mt--30 ml--20" href="#button">ขอใบเสนอราคา</a>
                            </div>
                        </div>
                    </div>
                    {/* End Call to Action Area  */}

                    {/* Start Footer Area  */}
                    <div className="footer-wrapper ptb--70">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                    <div className="ft-text">
                                        <div className="logo">
                                            <Link to="/">
                                                {logoUrl}
                                            </Link>
                                        </div>
                                        <p>บริษัท บั้งไฟออฟฟิเชียล จำกัด</p>
                                        <p>บริษัท บั้งไฟออฟฟิเชี่ยล จำกัด</p>
                                        <p>BANGFAI OFFICIAL CO.,LTD.</p>
                                        <p>เราคือทีมผลิตสื่อโฆษณา ที่มีประสบการณ์พร้อมไอเดียสร้างสรรค์ และใส่ใจทุกรายละเอียด พร้อมสร้างงานให้ดีที่สุด เราจะพัฒนาคุณภาพของงานให้ดียิ่งขึ้น พร้อมเปิดโอกาสเรียนรู้สิ่งใหม่ๆตลอดเวลา</p>
                                        <p>Copyright © 2023 <Link to="/">BANGFAI OFFICIAL</Link></p>
                                    </div>
                                </div>

                                {/* Start Single Widget  */}
                                <div className="col-lg-2 col-xl-2 offset-xl-1 col-md-6 col-sm-6 col-12 mt_mobile--40">
                                    {/* <div className="footer-link">
                                        <h4>Quick Link</h4>
                                        <ul className="ft-link">
                                            <li><Link to="/portfolio">Portfolio</Link></li>
                                            <li><Link to="/about">About</Link></li>
                                            <li><Link to="/blog">Our Blog</Link></li>
                                            <li><Link to="/team">Our Team</Link></li>
                                            <li><Link to="/contact">Contact</Link></li>
                                        </ul>
                                    </div> */}
                                </div>
                                {/* End Single Widget  */}

                                {/* Start Single Widget  */}
                                <div className="col-lg-2 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                                    <div className="footer-link">
                                        <h4>Menu</h4>
                                        <ul className="ft-link">
                                            <li><Link to="#about">บริการของเรา</Link></li>
                                            <li><Link to="#portfolio">ผลงาน</Link></li>
                                            <li><Link to="about">ทำไมต้องเลือกเรา?</Link></li>
                                            <li><Link to="contact">ติดต่อเรา</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* End Single Widget  */}

                                {/* Start Single Widget  */}
                                <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                                    <div className="footer-link">
                                        <h4>ช่องทางติดต่อ</h4>
                                        <ul className="ft-link">
                                            <li>Email: <a href="mailto:bangfaiofficial.th@gmail.com">bangfaiofficial.th@gmail.com</a></li>
                                            <li>LIND ID: bangfaiofficial</li>
                                            <li>Phone: 0960010297</li>
                                        </ul>

                                        <div className="social-share-inner mt--20">
                                            <ul className="social-share social-style--2 d-flex justify-content-start liststyle">
                                                {SocialShare.map((val, i) => (
                                                    <li key={i}><a href={`${val.link}`} target="_blank">{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* End Single Widget  */}
                            </div>
                        </div>
                    </div>
                    {/* End Footer Area  */}
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;
