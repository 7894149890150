import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import GoogleMapReact from 'google-map-react';
import ContactTwo from "./contact/ContactTwo";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";

import { FaInstagram, FaFacebookF, FaLine } from "react-icons/fa";
import { SiTiktok } from "react-icons/si";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const SocialShare = [
    { Social: <FaFacebookF />, link: 'https://www.facebook.com/bangfaiofficial' },
    { Social: <FaInstagram />, link: 'https://www.instagram.com/bangfaiofficial/' },
    { Social: <FaLine />, link: 'https://lin.ee/GyauX4t1' },
    { Social: <SiTiktok />, link: 'https://www.tiktok.com/@bangfai.official' },
]

class Contact extends Component {
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render() {
        return (
            <React.Fragment>
                <Header logoname="logo.png" />

                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area active-dark ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mt--50 text-center">
                                    <h2 className="title">ติดต่อเรา</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Phone</h4>
                                        <p><a href="tel:+66960010297">0960010297</a></p>
                                        <p><a href="tel:+66957843895">0957843895</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Email</h4>
                                        <p><a href="mailto:admin@gmail.com">bangfaiofficial@gmail.com</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Location</h4>
                                        <p>169/10 ถนน ห้าธันวาคม ในเมือง<br />อำเภอเมืองยโสธร ยโสธร 35000</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="social-icon">
                        <div className="row">
                            
                                
                                    <div className=" mt--20">
                                        <ul className="social-share social-style--3 d-flex liststyle">
                                            {SocialShare.map((val, i) => (
                                                <li key={i}><a href={`${val.link}`} target="_blank">{val.Social}</a></li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactTwo />
                </div>
                {/* End Contact Page Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />
            </React.Fragment>
        )
    }
}
export default Contact