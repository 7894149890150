import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import AboutComp from "../component/HomeLayout/homeOne/AboutTwo";

class About extends Component {
    render() {
        return (
            <React.Fragment>
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />


                {/* Start About Area */}
                <div className="about-area active-dark ptb--120">
                    <AboutComp />
                </div>
                {/* End About Area */}

                {/* Start CounterUp Area */}
                <div className="rn-counterup-area ptb--80 bg_color--10">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="subtitle">เราเต็มที่กับทุกงาน</span>
                                    <h2 className="title">เพราะลูกค้าคือคนสำคัญ</h2>
                                    <p className="description">พร้อมดูแลและให้คำปรึกษา</p>
                                </div>
                                {/* <div className="rn-testimonial-area bg_color--5 mt--80">
                                    <div className="container">
                                        <Testimonial />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Brand Area */}
                <div className="rn-brand-area bg_color--1 ptb--80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center mb--30">
                                    <span className="subtitle">Our clients</span>
                                    <h2 className="title">ลูกค้าที่เคยใช้บริการ</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default About