import React, { Component } from "react";

class AboutTwo extends Component {
    render() {
        let title = 'About',
            description = 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum,';
        return (
            <React.Fragment>
                <div className="about-wrapper  mt--50">
                    <div className="container">
                        <div className="row row--20 align-items-center">

                            <div className="col-lg-6 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-3.jpg" alt="About Images" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <span className="subtitle">เราทำอะไร</span>
                                        <h2 className="title">ทำไมต้องเลือกเรา</h2>
                                        <p className="description">
                                            ทีมบั้งไฟออฟฟิเชี่ยล ของเราเริ่มต้นจากจุดเริ่มที่ไม่มีอะไร แต่ด้วยความมุ่งมั่น, ความทุ่มเท,
                                            และความเชื่อมั่นในความฝันของเรา เราได้เติบโตและก้าวกระโดดไปสู่ความสำเร็จที่ยิ่งใหญ่
                                            การทำงานร่วมกับ BMW Thailand เป็นหลักฐานของความทุ่มเทและความเป็นมืออาชีพของเรา
                                            แต่ละวันที่เราทำงานด้วยความเหนื่อยล้านั้นเป็นการเสียสละที่คุ้มค่า
                                            เพราะทุกความพยายามของเราไม่ได้เป็นเพียงการทำงาน แต่เป็นการสร้างศิลปะ,
                                            สร้างนวัตกรรม, และสร้างแรงบันดาลใจ เราภาคภูมิใจที่ได้เป็นส่วนหนึ่งของการเปลี่ยนแปลงนี้
                                            และเราจะไม่หยุดที่จะเติบโต ทีมบั้งไฟออฟฟิเชี่ยล, เราเป็นมากกว่าทีม,
                                            เราเป็นครอบครัวที่มีหัวใจและจิตวิญญาณในการสร้างสรรค์อย่างไม่หยุดยั้ง
                                        </p>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h5 className="title">Creative</h5>
                                                <p>เราคือทีมผลิตสื่อโฆษณาที่มีประสบการณ์พร้อมไอเดียสร้างสรรค์</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h5 className="title">Strategy</h5>
                                                <p>เราเน้นสร้างความแตกต่างและความเป็นเอกลักษณ์ให้กับผลิตภัณฑ์หรือบริการ</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h5 className="title">Development</h5>
                                                <p>เราพัฒนาคุณภาพของงานให้ดียิ่งขึ้น พร้อมเปิดโอกาสเรียนรู้สิ่งใหม่ๆตลอดเวลา</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h5 className="title">Quality</h5>
                                                <p>เราใส่ใจทุกรายละเอียด เพื่อให้งานออกมาดีที่สุด</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="purchase-btn mt--50">
                                        <a className="btn-transparent">เราเชื่อว่าผลลัพธ์ที่ดีมาจากความพยายามของทุกคน</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutTwo;